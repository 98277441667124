import React, { useEffect } from "react";

const CalendlyWidget = ({ campaign, practitionerLink }) => {
  let link =
    campaign === "bsVIPUserCampaign"
      ? "https://calendly.com/bizziegold/vip-bpm-report-review?primary_color=8700ff"
      : campaign === "practitionerUserCampaign"
      ? practitionerLink
      : "https://calendly.com/gordon_breakmethod/brain-pattern-mapping";
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);

  return (
    <div>
      <div
        className="calendly-inline-widget"
        data-url={link}
        style={{ minWidth: "320px", height: "700px" }}
      />
    </div>
  );
};

export default CalendlyWidget;
